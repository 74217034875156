<template>
  <div class="content">
    <TitleInfo title1="数据监控" title2="维护记录"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div style="margin-left: 10px">设备名称：</div>
          <el-select v-model="queryForm.deviceID" placeholder="Select">
            <el-option
              v-for="item in deviceInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">维护时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
        </div>
        <div class="operate">
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button
            v-if="user.CanAdd"
            :icon="Plus"
            type="warning"
            :round="false"
            @click="handleDialogValue()"
            >新增</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="DeviceName" label="设备名称"></el-table-column>
          <el-table-column prop="Content" label="维护内容"></el-table-column>
          <el-table-column prop="Maintainer" label="维护人"></el-table-column>
          <el-table-column prop="Creator" label="创建人"></el-table-column>
          <el-table-column
            prop="LastEditor"
            label="最后编辑人"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="创建时间"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="LastEditTime"
            label="最后编辑时间"
          ></el-table-column>
          <el-table-column
            prop="action"
            label="操作"
            width="400"
            v-if="user.CanDelete || user.CanEdit"
          >
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
              <el-button
                v-if="user.CanDelete"
                size="small"
                type="danger"
                :icon="Delete"
                @click="del(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowValue="rowInfo"
      v-if="dialogVisible"
      @initList="initGetList"
    ></Dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  Search,
  Refresh,
  Plus,
  Edit,
  Delete,
  RefreshRight
} from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import {
  GetMaintainRecordPageList,
  DeleteMaintainRecord
} from '@/api/MaintainRecord'
import { GetDeviceFillList } from '@/api/Device'
import Dialog from './components/dialog.vue'
import { useStore } from 'vuex'
import moment from 'moment'
const queryForm = ref({
  deviceID: '',
  dtStart: new Date(),
  dtEnd: new Date().getDate() - 3600 * 1000 * 24 * 7,
  pageIndex: 1,
  pageSize: 10
})
const deviceInfo = ref([])
const loading = ref(true)
const loadingText = ref('数据加载中...')
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      return [moment().subtract(7, 'days').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      return [moment().subtract(1, 'months').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      return [moment().subtract(3, 'months').calendar(), moment().endOf('day')]
    }
  }
])
const dateRang = ref([moment().startOf('day'), moment().endOf('day')])
const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const dialogTitle = ref('')
const rowInfo = ref({})
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  initDeviceList()
})
const initDeviceList = () => {
  deviceInfo.value.length = 0
  deviceInfo.value.push({
    ID: '',
    Name: '全部'
  })
  GetDeviceFillList()
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            deviceInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          queryForm.value.deviceID = deviceInfo.value[0].ID
          initGetList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initGetList = async () => {
  loadingText.value = '数据加载中...'
  loading.value = true
  total.value = 0
  tableData.length = 0
  //   console.log(moment(dateRang.value[0]).format('yyyy-MM-D HH:mm:ss'))
  //   console.log(moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss'))
  queryForm.value.dtStart = moment(dateRang.value[0]).format(
    'yyyy-MM-D HH:mm:ss'
  )
  queryForm.value.dtEnd = moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss')
  GetMaintainRecordPageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加维护记录'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改维护记录'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前记录?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteMaintainRecord({ ID: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => {})
}
const resetInfo = () => {
  queryForm.value = {
    roleID: '',
    name: '',
    account: '',
    phone: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
