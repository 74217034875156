import $http from '@/utitls/request'

/**
 * 分页获取维护记录信息
 */
export const GetMaintainRecordPageList = (data) => {
    return $http.get('/api/MaintainRecord/GetMaintainRecordPageList', { params: data })
}
/**
 * 新增
 */
export const AddMaintainRecord = (data) => {
    return $http.post('/api/MaintainRecord/AddMaintainRecord', data)
}
/**
 * 修改
 */
export const EditMaintainRecord = (data) => {
    return $http.put('/api/MaintainRecord/EditMaintainRecord', data)
}
/**
 * 删除
 */
export const DeleteMaintainRecord = (data) => {
    return $http.delete('/api/MaintainRecord/DeleteMaintainRecord', { params: data })
}