<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="维护设备" prop="DeviceID">
        <el-select v-model="form.DeviceID" style="width: 100%">
          <el-option
            v-for="item in DeviceList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="维护时间" prop="CreateTime">
        <el-date-picker
          v-model="form.CreateTime"
          type="datetime"
          placeholder="请选择维护时间"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="维护人员" prop="Maintainer">
        <el-input v-model="form.Maintainer" autocomplete="off"> </el-input>
      </el-form-item>
      <el-form-item label="维护内容" prop="Content">
        <el-input v-model="form.Content" type="textarea" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.Remark" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { GetDeviceFillList } from '@/api/Device'
import { AddMaintainRecord, EditMaintainRecord } from '@/api/MaintainRecord'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { Search } from '@element-plus/icons-vue'

const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({})
const dialogVisible = ref(false)
const rules = reactive({
  DeviceID: [{ required: true, message: '设备名称不能为空', trigger: 'blur' }],
  CreateTime: [
    { required: true, message: '维护时间不能为空', trigger: 'blur' }
  ],
  Maintainer: [
    { required: true, message: '维护人员不能为空', trigger: 'blur' }
  ],
  Content: [{ required: true, message: '维护内容不能为空', trigger: 'blur' }]
})
const DeviceList = ref([])

onMounted(() => {
  GetDeviceFillList()
    .then((res) => {
      DeviceList.value.length = 0
      res.Data.forEach((element) => {
        DeviceList.value.push(element)
      })
    })
    .catch((err) => {})
})
const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  if (props.dialogTitle === '修改维护记录') {
    form.value = props.dialogRowValue
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加维护记录') {
        AddMaintainRecord(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditMaintainRecord(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
